import firebase from "firebase";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "*",
    redirect: "/"
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    path: "/upload",
    name: "Upload",
    component: () =>
      import(/* webpackChunkName: "upload" */ "../views/Upload.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/galleries",
    name: "Galleries",
    component: () =>
      import(/* webpackChunkName: "galleries" */ "../views/Galleries.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/galleries/:gallery",
    name: "Gallery",
    component: () =>
      import(/* webpackChunkName: "gallery" */ "../views/Gallery.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/creator/:index",
    name: "Creator",
    component: () =>
      import(/* webpackChunkName: "creator" */ "../views/Creator.vue"),
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const currentUser = firebase.auth().currentUser;

  if (requiresAuth && !currentUser)
    next({ path: "/login", query: { redirect: to.fullPath } });
  else if (!requiresAuth && currentUser) next("/");
  else if (!requiresAuth && !currentUser) next();
  else next();
});
export default router;
