import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

Vue.config.productionTip = false;

const config = {
  apiKey: "AIzaSyAoU2T8jMQZr8RcowlSkn-vqFQcdOpIvlE",
  authDomain: "visual-creator.firebaseapp.com",
  databaseURL: "https://visual-creator.firebaseio.com",
  projectId: "visual-creator",
  storageBucket: "visual-creator.appspot.com",
  messagingSenderId: "153519353358",
  appId: "1:153519353358:web:c05019c8e2c866510f165f"
};

firebase.initializeApp(config);

let app: Vue;
firebase.auth().onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount("#app");
  }
});
export const db = firebase.firestore();
