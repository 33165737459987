




































































import firebase from "firebase";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "App",
  data: () => ({
    menu: false
  }),
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
    logout() {
      console.log("log out me");
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "Login"
          });
        });
    }
  }
});
