import Vue from "vue";
import firebase from "firebase";
import Vuex from "vuex";
import { db } from "@/main";
Vue.use(Vuex);
export type ImageItem = {
  name: string;
  id: string;
  image: string;
  gallery: string;
};
export default new Vuex.Store({
  state: {
    folders: [],
    images: [],
    loading: false,
    error: "",
    user: {
      loggedIn: false,
      data: null
    }
  },
  mutations: {
    SET_IMAGES(state, images) {
      state.images = images;
    },
    SET_LOADING(state, status) {
      state.loading = status;
    },
    SET_ERROR(state, message) {
      state.error = message;
    },
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    }
  },
  actions: {
    async fetchGalleryImages({ commit }, gallery) {
      console.log({ gallery });
      const koebbeRef = db
        .collection("clients")
        .doc("koebbe")
        .collection("images")
        .where("gallery", "==", gallery);
      const items: ImageItem[] = [];
      koebbeRef
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            const data: ImageItem = doc.data() as ImageItem;
            items.push({ ...data, id: doc.id });
          });
          console.log("set images", items);

          commit("SET_IMAGES", items);
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
        });
    },
    async fetchImages({ commit }, folder) {
      commit("SET_LOADING", true);
      const storageRef = firebase.storage().ref();
      const listRef = storageRef.child(`koebbe/${folder}`);

      listRef
        .listAll()
        .then(res => {
          const items: firebase.storage.Reference[] = [];
          res.items.forEach(function(itemRef) {
            items.push(itemRef);
          });

          const filteredItems = items.filter(ref =>
            ref.fullPath.includes("800x800")
          );
          const filteredUrls: {
            url: string;
            item: firebase.storage.Reference;
          }[] = [];
          filteredItems.map(item => {
            if (item.fullPath.includes("800x800")) {
              item.getDownloadURL().then(url => {
                filteredUrls.push({ url, item });
              });
            }
          });

          commit("SET_IMAGES", filteredUrls);
          commit("SET_LOADING", false);
        })
        .catch(error => {
          console.log(error.message);
          commit("SET_ERROR", error.message);
          commit("SET_LOADING", false);
        });
    },
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email
        });
      } else {
        commit("SET_USER", null);
      }
    }
  },
  getters: {
    user(state) {
      return state.user;
    }
  },
  modules: {}
});
